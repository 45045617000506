@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.static-tiles {
    padding: 30px 0;

    &.margins-enabled {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 21px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 31px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }

    .title {
        margin-bottom: 30px;
        font-weight: 500;

        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }
}
